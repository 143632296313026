/* NotebookTextEditor.css */

.notebook-editor-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    max-width: 100%;
    margin: 20px auto;
    font-family: 'Courier New', Courier, monospace;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .toolbar {
    display: flex;
    border-bottom: 1px solid #ccc;
    background-color: rgba(128, 128, 128, 0.1);
    padding: 5px;
  }
  
  .toolbar button {
    background: none;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    color: invert(100%);
  }
  
  .toolbar button:hover {
    background-color: #e0e0e0;
  }
  
  .notebook-textarea {
    position: relative;
    padding: 24px;
    padding-top: 31.5px;
    min-height: 300px;
    line-height: 1.6;
    background-image: linear-gradient(to bottom, rgba(128,128,128, 0.3) 1px, transparent 1px);
    background-size: 100% 25.58px;
    overflow-y: auto;
  }
  
  /* Optional: Customize the caret color */
  .notebook-textarea:focus {
    outline: none;
  }
  