.App {
  text-align: center;
}
.selected {
  background-color: #30bdff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-grid-item > .react-resizable-handle{
  filter: invert(0.5);
  width: 20px !important;
  height: 20px !important;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 10px !important;
  right: 10px !important;
  scale: 2;
  cursor: se-resize;
}

.ant-table-cell .ant-table .ant-table-small .ant-table-content .ant-table-tbody .ant-table-container .ant-table-row .ant-table-row-level-0 .ant-table .ant-table-small .table .css-dev-only-do-not-override-n9au3c .ant-table-scroll-horizontal ::before{
  background-color:rgba(0, 0, 0, 0) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:where(.css-dev-only-do-not-override-1njdcvp).ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane, :where(.css-dev-only-do-not-override-1njdcvp).ant-tabs-left >div>.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane{
  padding-left: 0px !important;
}

:where(.css-dev-only-do-not-override-16xcakp).ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane, :where(.css-dev-only-do-not-override-16xcakp).ant-tabs-left >div>.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane{
  padding-left: 0px !important;
}

#mainCallButton {
  background-color: #00bcd4;
  color: rgb(255, 255, 255);
  padding: 4px 20px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  font-size: 16px;
  cursor: pointer;
}


#hangupButton {
  background-color: red;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

#noAnswer {
  animation: glow 1s infinite;
}

@keyframes glow {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #ffffff;
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 10px #ffffff;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #ffffff;
  }
}
/* Container for the row with gutter */
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -8px; /* Half of the gutter to offset padding */
}

/* Column styles */
.col {
  padding: 8px; /* Gutter space */
  box-sizing: border-box;
  width: 100%; /* Default to full width (xs=24) */
}

/* For small screens and above (sm=12) */
@media (min-width: 576px) {
  .col-sm-12 {
    width: 100%;
  }
}

/* Statistic box styling */
.statistic {
  border: 0px solid #f0f0f0;
  border-radius: 0px;
  padding: 0px;
  text-align: left;
  animation: fade-in 1s ease-in-out;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.statistic-title {
  font-size: 16px;
  margin-bottom: 0px;
}

.statistic-value {
  font-size: 16px;
  font-weight: bold;
}
.App-header {
  background-color: #e5edea;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* App.css */
.grid-item {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px;
  overflow: auto;
}
.grid-item.dark-mode {
  background: #1c1c1c;
  border: 1px solid #434343;
  color: #ffffff;
}
.grid-item-header {
  padding: 16px;
  cursor: move; /* Show move cursor when hovering over the header */
}
.grid-item-content {
  padding: 32px;
}
.App-link {
  color: #61dafb;
}

.selected {
  background-color: #e6f7ff; /* Light blue background for selected row */
  color:#1c1c1c;
}
.selected:hover {
  background-color: #65767e; /* Light blue background for selected row */
  color:#1c1c1c;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
